import React from 'react';
import { Modal } from '../Modal';
import { Button } from '../Button';
import styles from './styles.module.css';
import { OptionSetType } from 'V2/Types/StepConfig';

type LineItemType = {
  description: string;
  amount: string;
  title?: string;
};

const downPaymentMap = {
  down_payment_premium: 'Policy Premium',
  down_payment_policy_fee: 'Policy Fee',
  down_payment_vehicle_fees: 'Vehicle Fees',
  down_payment_installment_fees: 'Installment Fees',
  down_payment_member_surplus_contribution: 'Member Surplus Contribution',
  down_payment_taxes: 'Taxes',
};

const totalPaymentMap = {
  total_premium: 'Policy Premium',
  total_policy_fee: 'Policy Fee',
  total_vehicle_fees: 'Vehicle Fees',
  total_installment_fees: 'Installment Fees',
  total_member_surplus_contribution: 'Member Surplus Contribution',
  total_taxes: 'Taxes',
};

export type FeeModalType = {
  isModalVisible: boolean;
  setIsModalVisible: (showModal: boolean) => void;
  optionSet: OptionSetType[];
  isTotalDueModal?: boolean;
};

export const LineItem: React.FC<LineItemType> = ({ description, amount }) => (
  <div className={styles.lineItem}>
    <p>{description}</p>
    <p>{amount}</p>
  </div>
);
export const FeeModal: React.FC<FeeModalType> = ({
  isModalVisible,
  setIsModalVisible,
  optionSet,
  isTotalDueModal = false,
}) => {
  const closeModal = () => setIsModalVisible(false);
  const titleMap = isTotalDueModal ? totalPaymentMap : downPaymentMap;
  const totalNewLine = {
    ...(isTotalDueModal
      ? optionSet.find((os) => os.field_id === 'total_premium_and_fees')
      : optionSet.find(
          (os) => os.field_id === 'down_payment_premium_and_fees'
        )),
    title: isTotalDueModal ? 'Total cost for 6-month policy' : 'Due Today',
  };

  const modalTitle = isTotalDueModal ? 'Total Cost' : 'Total Due Today';

  const lineItemsArray = Object.entries(titleMap).reduce(
    (acc, [key, title]) => {
      const optionSetItem = optionSet.find((os) => os.field_id === key);
      if (optionSetItem && Boolean(optionSetItem.label)) {
        acc.push({
          key,
          description: title,
          amount: optionSetItem.label,
        });
      }
      return acc;
    },
    new Array<{ key: string; description: string; amount: string }>()
  );

  if (!isModalVisible) return <></>;
  return (
    <Modal
      title={modalTitle}
      showModal={isModalVisible}
      closeAction={closeModal}
    >
      <div className={styles.lineItems}>
        {lineItemsArray.map(({ key, description, amount }) => (
          <LineItem description={description} amount={amount} key={key} />
        ))}
      </div>
      <div className={styles.totalLine}>
        <p>{totalNewLine.title}</p>
        <p>{totalNewLine.label}</p>
      </div>
      <div>
        <Button
          variant="contained"
          size="md"
          onClick={closeModal}
          className={styles.closeButton}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
