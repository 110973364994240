import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LogToService } from 'V2/Utilities/Logger';
import { Apply, useApplyProvider } from 'V2/Apply';
import { Edit, useEditMode } from 'V2/Edit';
import { ErrorRouting as ErrorBanner } from 'V2/Shared/ErrorRouting';
import { useApi } from 'V2/Api';
import { buildFields } from 'V2/Step';
import { useTheme } from 'V2/Shared/ThemeProvider';
import { TransitionDuration } from 'V2/Shared/Animations';
import { Formotiv, Survicate } from 'V2/Analytics';

export const App: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const {
    setApp,
    isReady,
    app: { status, fields, appId },
  } = useApplyProvider();
  const { flowSequence } = useApi();
  const { isEditMode } = useEditMode();

  const isSystemProcess = useCallback(
    () => fields.find(({ entity_type: { id } }) => id === 'system_process'),
    [fields]
  );

  const initFormotiv = useCallback(
    (appId: string) => {
      if (appId && !isSystemProcess()) {
        Formotiv.init(appId);
      }
    },
    [isSystemProcess]
  );

  const sendVisitorTraitsToSurvicate = useCallback(
    (appId: string, partnerName: string) => {
      const survicateConfig = {
        application_id: appId,
        partner_name: partnerName,
      };

      Survicate.setVisitorTraits(survicateConfig);
    },
    []
  );

  useEffect(() => {
    if (isReady && !status.id) {
      flowSequence()
        .then((response) => {
          const builtFields = buildFields(
            response.status.field_data,
            response.config
          );
          setApp((app) => ({
            ...app,
            appId: response.status.insurance_application_id,
            status: response.status,
            config: response.config,
            summary: response.summary,
            fields: builtFields,
          }));

          const applicationId = response.status.insurance_application_id;

          // call formotiv init on first load
          initFormotiv(applicationId);

          sendVisitorTraitsToSurvicate(
            applicationId,
            response.status.partner.name
          );

          history.push({
            pathname: `/apply/${response.status.id}`,
            search: history.location.search,
          });
        })
        .catch((error) => {
          LogToService.captureException({
            errorType: 'server',
            message: error.message,
            thread: 'app_init',
            error: new Error(error),
          });
          history.push(`/error?errorType=app_init`);
        });
    }
  }, [
    isReady,
    flowSequence,
    history,
    setApp,
    status.id,
    initFormotiv,
    sendVisitorTraitsToSurvicate,
  ]);

  useEffect(() => {
    if (appId) {
      // call formotiv init on subsequent screens
      initFormotiv(appId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { setTheme } = useTheme();

  const whiteLabel = useMemo(() => {
    return status.white_label;
  }, [status.white_label]);

  useEffect(() => {
    if (whiteLabel) {
      if (whiteLabel.is_choice_flow) {
        setTheme(whiteLabel);
      } else {
        setTimeout(() => {
          // adding a timeout so that a partner's call-to-action button remains their theme
          // until the page transitions into a clearcover flow
          setTheme({ theme: 'clearcover', is_choice_flow: false });
        }, TransitionDuration.milliseconds);
      }
    }
  }, [whiteLabel, setTheme]);

  return (
    <>
      {isEditMode ? <Edit /> : <Apply />}
      <ErrorBanner />
    </>
  );
};
