import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import * as Sentry from '@sentry/react';
import { env } from 'constants/env';
import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { GoogleAnalytics } from 'V2/Analytics';
import { ApiProvider } from 'V2/Api';
import { App } from 'V2/App';
import { ApplyProvider } from 'V2/Apply';
import { DocumentRender } from 'V2/DocumentRender';
import { EditProvider } from 'V2/Edit';
import { Search } from 'V2/Search';
import { postPageView } from 'V2/Services/PostPageView';
import { ErrorScreen } from 'V2/Shared/ErrorScreen';
import { IEBanner } from 'V2/Shared/IEBanner';
import { QuoteScreen } from 'V2/Shared/QuoteScreen';
import { ThemeProvider } from 'V2/Shared/ThemeProvider';
import { PaymentProvider } from 'V2/Steps/PaymentDetails';
import { LogToService } from 'V2/Utilities/Logger';
import { MonocleProvider } from '@spur.us/monocle-react';
import { DesignVersioningProvider } from 'V2/Shared/DesignVersioning';
import MonocleLoader from '@spur.us/monocle-loader';

const optimizely = createInstance({
  eventBatchSize: 10,
  eventFlushInterval: 1000,
  sdkKey: env.optimizelySdkKey,
});

export const Routes: React.FC = () => {
  const history = useHistory();
  const m = MonocleLoader({
    token: `${env.spurMonocleToken}`,
  });
  useEffect(() => {
    /*
      since this project is a SPA, we need to send every route change
      to analytics vendors that dont support client-side route changes
    */
    // @ts-ignore
    history.listen((historyLocation) => {
      GoogleAnalytics.send('pageview', historyLocation.pathname);

      // We post insurance app page views to Reznor.
      // These are forwarded to IdentityAPI, identified,
      // then published onto a topic as an event.
      // that event is consumed by Notification Worker
      // Notification worker then forwards that event to external services.
      // currently, this is CustomerIO.
      const appId = sessionStorage.getItem('appId');
      if (!appId) return;
      postPageView({
        appId,
        url: `${historyLocation.pathname}`,
        data: historyLocation,
      }).catch((error) => {
        if (error.response && error.response.data.http_error_code >= 500) {
          LogToService.captureException({
            errorType: 'server',
            message: error.response.data.message,
            stack: error.response.data.parent,
            thread: 'reznorPageViewApi',
            error: new Error(error),
          });
        }
      });
    });
  }, [history]);

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        id: `user`,
        attributes: {
          hostname: window.location.host,
        },
      }}
    >
      <Sentry.ErrorBoundary
        fallback={<ErrorScreen />}
        onError={(error: any) => {
          LogToService.event('error', {
            error_type: 'client',
            thread: 'ErrorBoundary',
            message: error.message,
          });
        }}
      >
        <MonocleProvider instance={m}>
          <ThemeProvider>
            <ApiProvider>
              <ApplyProvider>
                <EditProvider>
                  <PaymentProvider>
                    <DesignVersioningProvider>
                      <Switch>
                        <Route exact path="/" component={App} />
                        <Route path="/apply" component={App} />
                        <Route path="/agent/apply" component={App} />
                        <Route path="/documents" component={DocumentRender} />
                        <Route path="/error" component={ErrorScreen} />
                        {[
                          'development',
                          'ephemeral',
                          'staging',
                          'uat',
                          'partner',
                        ].indexOf(env.clearcoverEnv) !== -1 && (
                          <Route path="/quote" component={QuoteScreen} />
                        )}
                      </Switch>
                    </DesignVersioningProvider>
                  </PaymentProvider>
                </EditProvider>
              </ApplyProvider>
            </ApiProvider>
            <Route path="/search" component={Search} />
            <IEBanner />
          </ThemeProvider>
        </MonocleProvider>
      </Sentry.ErrorBoundary>
    </OptimizelyProvider>
  );
};
