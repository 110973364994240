import React from 'react';

import styles from './styles.module.css';
import { CircleInfo } from '../Icons';

type CircleInfoButtonType = {
  onClick?: () => void;
};

export const CircleInfoButton: React.FC<CircleInfoButtonType> = ({
  onClick,
}) => (
  <button
    type="button"
    className={styles.button}
    onClick={onClick}
    data-testid="circle-info-button"
  >
    <CircleInfo />
  </button>
);
