import { datadogRum } from '@datadog/browser-rum';
import { Context as ContextType } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';
import { env } from 'constants/env';
import {
  HeapType,
  FullStoryType,
  DataLayerType,
  DatadogType,
  GoogleAnalyticsType,
  HeapConfigType,
  FullStoryConfigType,
  FormotivType,
  SurvicateType,
} from './types';
import { getScript as getHeapScript } from './Heap';
import { getScript as getFullStoryScript } from './FullStory';
import { scriptSrc as formotivScriptSrc } from './Formotiv';

declare global {
  interface Window {
    heap: any;
    FS: any;
    _cio: any;
    dataLayer: any;
    ga: any;
  }
}

let isHeapEnabled = true;
export const Heap: HeapType = {
  init: (config: HeapConfigType) => {
    const scriptId = 'cc-heap-script';
    const { appId, enabled = false } = config;

    const isHeapScriptAlreadyLoaded = !!document.getElementById(scriptId);

    isHeapEnabled = enabled;

    if (!enabled) return;
    if (isHeapScriptAlreadyLoaded) return;
    if (!document || !document?.head) return;

    const heapScript = getHeapScript(appId);

    const script = document.createElement('script');
    script.id = scriptId;
    script.innerHTML = heapScript;

    document.head.appendChild(script);
  },
  track: (event, properties = {}) => {
    if (!isHeapEnabled || !window.heap || !window.heap.track) return;
    window.heap.track(event, properties);
  },
  identify: (id) => {
    if (!isHeapEnabled || !window.heap || !window.heap.identify) return;
    window.heap.identify(id);
  },
  addEventProperties: (properties) => {
    if (!isHeapEnabled || !window.heap || !window.heap.addEventProperties)
      return;
    window.heap.addEventProperties(properties);
  },
  addUserProperties: (properties) => {
    if (!isHeapEnabled || !window.heap || !window.heap.addUserProperties)
      return;
    window.heap.addUserProperties(properties);
  },
  trackOptimizelyExperiment: (experiment, variation) => {
    if (!variation || !window.heap || !window.heap.track) return;
    window.heap.track(`Optimizely Experiment: ${experiment}`, {
      variant: variation,
    });
  },
};

let isFullStoryEnabled = true;
export const FullStory: FullStoryType = {
  init: (config: FullStoryConfigType) => {
    const scriptId = 'cc-fullstory-script';
    const { appId, enabled = false, isOuterScript = false } = config;

    const isFullStoryScriptAlreadyLoaded = !!document.getElementById(scriptId);

    isFullStoryEnabled = enabled;

    if (!enabled) return;
    if (isFullStoryScriptAlreadyLoaded) return;
    if (!document || !document?.head) return;

    const fullStoryScript = getFullStoryScript(appId, isOuterScript);

    const script = document.createElement('script');
    script.id = scriptId;
    script.innerHTML = fullStoryScript;

    document.head.appendChild(script);
  },
  identify: (id) => {
    if (!isFullStoryEnabled || !window.FS || !window.FS.identify) return;
    window.FS.identify(id);
  },
  setUserVars: (properties) => {
    if (!isFullStoryEnabled || !window.FS || !window.FS.setUserVars) return;
    window.FS.setUserVars(properties);
  },
  onReady: (callback) => {
    if (!isFullStoryEnabled || !window.FS) return;

    window['_fs_ready'] = function () {
      callback(window.FS);
    };
  },
  getCurrentSession: () => {
    if (!isFullStoryEnabled || !window.FS) return null;
    if (typeof window.FS.getCurrentSession !== 'function') return null;

    return window.FS.getCurrentSession();
  },
  track: (event, properties = {}) => {
    if (!isFullStoryEnabled || !window.FS) return;

    window.FS('trackEvent', {
      name: event,
      properties: properties,
    });
  },
};

const isEnabled = env.nodeEnv !== 'development';

export const DataLayer: DataLayerType = {
  push: (properties) => {
    if (!isEnabled || !window.dataLayer || !window.dataLayer.push) return;
    window.dataLayer.push(properties);
  },
};

export const GoogleAnalytics: GoogleAnalyticsType = {
  send: (event, location) => {
    if (!isEnabled || !window.ga) return;
    window.ga('send', event, location);
  },
};

export const Datadog: DatadogType = {
  /*
    Add additional Datadog context:
    https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#add-global-context
    Datadog naming convention for a better correlation of data:
    https://docs.datadoghq.com/logs/processing/attributes_naming_convention/#user-related-attributes
  */
  addUserContext: ({ appId, clickId }) => {
    datadogRum.setGlobalContextProperty('usr', {
      app_id: appId,
      click_id: clickId,
    } as ContextType);
  },
  identify: (id) => {
    datadogRum.setUser({ id });
  },
  logEvent: ({ level, message, messageContext }) => {
    const config = {
      ...messageContext,
      service: 'acquisition-app',
    };
    datadogLogs.logger[level](message, config);
  },
  logInfo: (eventName, eventAttributes) => {
    const config = {
      ...eventAttributes,
      service: 'acquisition-app',
    };
    datadogLogs.logger.info(eventName, config);
  },
};

export const Formotiv: FormotivType = {
  _enabled: env.formotivEnabled === '1',
  _formotiv: window.formotiv || undefined,
  _customInputNameHandler: (input: HTMLElement, defaultVal: string) => {
    const rawInputSplitted = input.getAttribute('name')?.split('.') || null;
    const entityIndex = input.dataset.entityIndex || null;

    if (!!rawInputSplitted && rawInputSplitted.length >= 2) {
      if (entityIndex) {
        return `${rawInputSplitted[1]}[${entityIndex}]`;
      }

      return rawInputSplitted[1];
    }

    return defaultVal;
  },
  load: function () {
    if (!this._enabled) return;

    const scriptId = 'cc-formotiv-script';
    const isFormotivScriptAlreadyLoaded = !!document.getElementById(scriptId);

    if (isFormotivScriptAlreadyLoaded) return;

    const script = document.createElement('script');
    script.id = scriptId;
    script.src = formotivScriptSrc;
    script.setAttribute('type', 'text/javascript');
    script.onload = () => {
      this._formotiv = window.formotiv;
    };
    document.head.appendChild(script);
  },
  getInstance: function () {
    return this._formotiv
      ? this._formotiv.FormotivDefaultConfig.Instance(env.formotivUserId)
      : undefined;
  },
  init: function (appId: string) {
    if (!this._enabled) return;

    try {
      const formotivConfig = this.getInstance();

      if (!!formotivConfig) {
        formotivConfig.api = {
          apihost: env.formotivApiHost,
        };
        formotivConfig.loglevel =
          env.clearcoverEnv !== 'production'
            ? this._formotiv.LogLevel.Debug
            : this._formotiv.LogLevel.Warn;
        formotivConfig.discovery.altURIHandler.enabled = true;
        formotivConfig.discovery.altURIHandler.getAltFormURI = function () {
          return window.location.pathname;
        };
        formotivConfig.discovery.customElementNamesHandler =
          this._customInputNameHandler;
        formotivConfig.setValue('passthru1', appId);
        formotivConfig.setValue('passthru4', env.clearcoverEnv);

        this._formotiv.init(formotivConfig);
      }
    } catch (error) {
      console.error(error);
    }
  },
  submit: function () {
    if (!this._enabled) return;
    try {
      const formotivConfig = this.getInstance();

      if (!!formotivConfig) {
        this._formotiv.submit(formotivConfig);
      }
    } catch (error) {
      console.error(error);
    }
  },
  submitFinal: function () {
    if (!this._enabled) return;
    try {
      const formotivConfig = this.getInstance();

      if (!!formotivConfig) {
        this._formotiv.submitFinal(formotivConfig);
      }
    } catch (error) {
      console.error(error);
    }
  },
};

export const Survicate: SurvicateType = {
  setVisitorTraits: function (properties = {}) {
    if (!window._sva) return;

    window._sva.setVisitorTraits({
      ...properties,
    });
  },
  invokeEvent: function (eventName) {
    if (!window._sva) return;

    window._sva.invokeEvent(eventName);
  },
};
