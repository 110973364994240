import { StatusType, StepConfigType } from 'V2/Types';
import { summaryDefaults } from 'V2/Apply/Defaults';

export const configDefault: StepConfigType = {
  title_text: '',
  features: [],
  features_by_key: {},
  options: {},
  option_sets: {},
  options_uris: [],
  step_descriptions: [],
  field_descriptions: [],
  validation_uri: null,
  policy_enforced_state: '',
  application_focus: {
    step_name: '',
    path: {
      identifier: '',
      entity_type: {
        identifier: '',
        sub_type: '',
      },
      sub_path: {},
    },
  },
};

const baseStatus = {
  editable: true,
  current_step_submit_uri: '',
  validation_uri: '',
  insurance_application_id: '',
  step_config_uri: '',
  analytics_uri: '',
  attribution_data_uri: '',
  field_data: [],
  summary_uri: '',
  id: '',
  label: '',
  description: '',
  progress_data: [],
  partner: {
    name: 'Clearcover',
    partner_code: 'cc',
    channel: 'direct',
    commission_structure: 'bid',
    configuration: {
      white_label: {
        logo: 'some_logo.jpg',
        title: 'Logo',
        theme: 'cc_theme',
      },
    },
  },
  quote_change_acknowledgement: {
    required: false,
    submit_uri: '',
    translations: {},
  },
};
export const statusDefault: StatusType = {
  ...baseStatus,
  step_status: baseStatus,
  summary: summaryDefaults,
  step_details: configDefault,
  attribution_data: {},
};

export const contextDefaults = {
  status: statusDefault,
  config: configDefault,
  setStatus: () => {},
  setConfig: () => {},
  resetStatus: () => {},
  resetConfig: () => {},
};
