import React, { ChangeEvent } from 'react';
import { Field } from 'formik';
import cx from 'classnames';
import { CheckboxWithLabel } from './CheckboxWithLabel';
import { FieldRenderProps } from '../types';
import { CheckboxButtonType } from './types';
import styles from './styles.module.css';

export const CheckboxButton: React.FC<CheckboxButtonType> = ({
  label,
  description,
  name,
  defaultChecked,
  onChange,
  'data-testid': dataTestId,
  className,
  ...inputProps
}) => (
  <div
    data-testid={`${name} checkbox-button ${dataTestId}`}
    className={cx(styles.checkbox, className)}
  >
    <Field name={name}>
      {({ field }: FieldRenderProps) => (
        <CheckboxWithLabel
          {...field}
          {...inputProps}
          name={name}
          defaultChecked={defaultChecked}
          label={label}
          description={description}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            field.onChange(e);
            typeof onChange === 'function' && onChange(e);
          }}
        />
      )}
    </Field>
  </div>
);
